import React from "react"
import { graphql } from "gatsby"
import { InsightCard } from "../../components/elements/card"
import Layout from "../../components/layout"
import GetInTouch from "../../components/sections/get-in-touch"
import backgroundImg from "../../images/Insights-header.jpg"
import StylisedText from "../../components/elements/stylised-text"
import ButtonLink from "../../components/elements/button-link"
import Seo from "@/components/seo"

const Category = ({ data, pageContext }) => {
  const global = data.strapiGlobal
  const insights = data.insights.edges
  const navbar = data.strapiNavbar
  const footer = data.strapiFooter
  const metadata = data.strapiGlobal.metadata

  return (
    <>
      <Seo seo={metadata} global={global} />
      <Layout
        navbar={navbar}
        footer={footer}
        pageContext={{ ...pageContext }}
        slug="insights"
      >
        <HardCodedHero />
        <section className="mt-24 xl:container mx-auto pb-12 2xl:px-32 xl:px-0 xs:px-8 flex flex-row justify-around xs:justify-around xs:gap-8 flex-wrap">
          {insights.map(insight => (
            <InsightCard
              data={insight.node}
              category={data.category.edges[0].node.slug}
            />
          ))}
        </section>
        <GetInTouch
          data={{
            __component: "sections.get-in-touch",
            id: 1,
            description:
              "Whether you’re ready to hire a Hooligan or just have a problem or project you want to discuss, drop us your details so we can get in touch. ",
            breadcrumbTitle: {
              id: 4,
              title: "Get in Touch",
              colour: "grey",
              colourOverride: null,
            },
            title: [
              {
                id: 68,
                text: "<",
                style: "bold",
                colour: "green",
                colourOverride: null,
              },
              {
                id: 69,
                text: "Hello ",
                style: "italic",
                colour: "orange",
                colourOverride: null,
              },
              {
                id: 70,
                text: "Hooli",
                style: "bold",
                colour: "white",
                colourOverride: null,
              },
              {
                id: 71,
                text: "/>",
                style: "bold",
                colour: "green",
                colourOverride: null,
              },
            ],
          }}
        />
      </Layout>
    </>
  )
}

export default Category

const HardCodedHero = ({ data }) => {
  return (
    <section className="hero hero--short">
      <div
        className="hero__overlay"
        style={{
          background:
            "linear-gradient(77.61deg, #001620 0%, rgba(0, 22, 32, 0.7) 100%);",
        }}
      ></div>
      <div
        className="hero__background bg-cover xs:bg-top"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      ></div>
      <div className="hero__container">
        <div className="hero__content__outer">
          <HeroContent data={data} />
        </div>
      </div>
    </section>
  )
}

const HeroContent = () => {
  return (
    <div className="hero__content__inner">
      <div>
        <StylisedText
          data={[
            {
              id: 72,
              text: "<",
              style: "bold",
              colour: "green",
              colourOverride: null,
            },
            {
              id: 73,
              text: "Insights ",
              style: "bold",
              colour: "white",
              colourOverride: null,
            },
            {
              id: 74,
              text: "& ",
              style: "italic",
              colour: "orange",
              colourOverride: null,
            },
            {
              id: 75,
              text: "Trends ",
              style: "bold",
              colour: "white",
              colourOverride: null,
            },
            {
              id: 76,
              text: "/>",
              style: "bold",
              colour: "green",
              colourOverride: null,
            },
          ]}
          type="h3"
        />
        <p
          dangerouslySetInnerHTML={{
            __html: "We like to stay ahead of what is happening in the world.",
          }}
          className="hero__smalltext hero__bodytext mb-8"
        ></p>
      </div>
      <div className="hero__btn hero__btn--top xlg:self-start">
        <ButtonLink
          button={{
            newTab: "false",
            text: "Take Me Back",
            type: "primaryback",
            url: "/insights",
          }}
          appearance="primaryback"
          key="1"
        />
      </div>
    </div>
  )
}

export const query = graphql`
  query Category($slug: String!) {
    strapiGlobal(locale: { eq: "en" }) {
        ...GlobalData
      }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
    insights: allStrapiInsight(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: {fields: [publishDate], order: DESC}
    ) {
      edges {
        node {
          id
          slug
          title
          blurb
          publishDate
          categories {
            name
            slug
          }
          cardBackground {
            ext
            url
            formats {
              large {
                url
              }
            }
          }
        }
      }
    }
    category: allStrapiCategory(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }

  fragment NavbarData on StrapiNavbar {
    button {
      id
      newTab
      text
      type
      url
    }
    id
    links {
      url
      text
      newTab
      id
    }
  }

  fragment FooterData on StrapiFooter {
    id
    columns {
      id
      links {
        id
        newTab
        text
        url
      }
      title
    }
    id
    smallText
    email
    policies {
      id
      newTab
      text
      pdf {
        url
      }
    }
  }
`
